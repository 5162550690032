/**
 * WHAT IS THIS FILE?
 *
 * Globally applied styles. No matter which components are in the page or matching route,
 * the styles in here will be applied to the Document, without any sort of CSS scoping.
 *
 */
 @tailwind base;
 @tailwind components;
 @tailwind utilities;
 
 :root {
   --qwik-dark-blue: #006ce9;
   --qwik-light-blue: #18b6f6;
   --qwik-light-purple: #ac7ff4;
   --qwik-dark-purple: #713fc2;
 }